import { withRouter } from "react-router-dom";
import { Component } from "react";
import Header from "./header";
import Login from "./account/login";
import Docs from "./Docs";
// import NewDocs from "./NewDocs";
import Spinner from "./helper/spinner";
// import SearchBar from "./helper/searchbar";
class App extends Component {
	queries() {
		let search = this.props.location.search;
		if (!search) return [];
		search = search.split("");
		// let s = search.shift();
		let items = search.join("").split("&");
		return items
			.map((u) => {
				let a = u.split("=");
				if (a && a[0] && a[1]) return { key: a[0], value: a[1] };
				return null;
			})
			.filter((u) => u);
	}
	state = {
		location: this.props.location.pathname,
		queries: this.queries(),
		auth: localStorage.getItem("auth"),
		userid: localStorage.getItem("userID"),
		type: localStorage.getItem("type"),
		policies: [],
		docType: "",
		docTypes: [],
		fpolicies: [],
		filteredCats: [],
		view_type: "grid",
	};
	componentWillMount() {
		window.app = this;
	}
	async componentDidMount() {
		if (this.state.location !== this.props.location.pathname) {
			this.setState({
				location: this.props.location.pathname,
				queries: this.queries(),
			});
		}
		if (this.state.auth) this.getPolicies().catch((e) => window.flash(e));
	}
	componentDidUpdate() {
		if (this.state.location !== this.props.location.pathname) {
			this.setState({
				location: this.props.location.pathname,
				queries: this.queries(),
			});
		}
	}
	getPolicies = (docTypes) => {
		// console.log("policy api called");
		// console.log("state value", this.state);
		// return;
		return new Promise((res, rej) => {
			this.gettingPolicies = true;
			window.app
				.request(
					docTypes ? "/policies" : "/all-policies",
					"post",
					{
						type: { $in: docTypes || ["policies"] },
					}
				)
				.then((r) => {
					this.gettingPolicies = false;

					// console.log("state value", this.state);
					// return;

					this.setState(
						{
							...this.state,
							policies: r.policies,
							fpolicies: r.policies,
							gotPolicies: true,
							docTypes: r.policies?.reduce((a, b) => {
								if (b.docTypes)
									b.docTypes.forEach((u) => {
										if (!a.includes(u)) a.push(u);
									});
								return a;
							}, []),
						},
						() => {
							res(r.policies);
						}
					);
				})
				.catch((e) => {
					console.log("fetch error", e);
					this.gettingPolicies = false;
					window.flash(
						"Unable to authenticate you, please log in again."
					);
					// window.app.logOut();
					rej(e);
				});
		});
	};
	request(page, method, data) {
		return new Promise((res, rej) => {
			fetch(window.API + page, {
				method,
				body: JSON.stringify(data),
				headers: {
					authorization: this.state.auth,
					userid: this.state.userid,
					"Content-Type": "application/json",
				},
			})
				.then((r) => r.json())
				.then((r) => {
					if (!r || r.error)
						return rej(r ? r.message : JSON.stringify(r));
					res(r);
				})
				.catch((e) => rej(e));
		});
	}
	new = () => {
		this.setState({ newDoc: true });
	};
	logOut = () => {
		localStorage.removeItem("userID");
		localStorage.removeItem("auth");
		localStorage.removeItem("type");
		this.setState({
			auth: null,
			type: null,
			userID: null,
			authenticated: false,
		});
		if (window.DESKTOP) {
			window.DESKTOP.LogOut(
				"https://policies.octanemultimedia.com/logout-cookie"
			)
				// window.DESKTOP.LogOut(window.API + "/logout-cookie")
				.then(() => {
					window.redirect(process.env.PUBLIC_URL + "/");
				})
				.catch((e) => window.flash(e));
		} else {
			// window.location = window.API + "/logout-cookie";
			// window.location = "http://localhost:3000/logout-cookie";
			window.location =
				"https://policies.octanemultimedia.com/logout-cookie";
		}
	};
	extractContent(text) {
		try {
			return JSON.parse(text)
				.blocks.map((u) => u.text)
				.join(" ");
		} catch (e) {
			console.log(e);
			return "";
		}
	}
	render() {
		// console.log("state auth", this.state.auth);
		if (!this.state.auth)
			return (
				<div className="App">
					<Header logged={false}></Header>
					<Login></Login>
				</div>
			);
		if (!this.state.gotPolicies)
			return (
				<div className="App">
					<Header logged={true}></Header>
					<div className="loading">
						<h3>Loading Documents</h3>
						<Spinner />
					</div>
				</div>
			);
		if (this.state.newDoc) {
			return (
				<div
					className="App"
					style={{
						color: "var(--l)",
						backgroundColor: "rgb(241,241,241)",
						paddingTop: "20px",
					}}
				>
					{this.state.nameError && (
						<span>We already have that type of document</span>
					)}
					<input
						style={{ color: "var(--d)" }}
						value={this.state.docType}
						placeholder="Type of document"
						onChange={(e) =>
							this.setState({
								docType: e.target.value,
								nameError: false,
							})
						}
					/>
					<button
						onClick={(e) => {
							if (
								!this.state?.docTypes
									.map((u) => u.toUpperCase())
									.includes(this?.state?.docType?.toUpperCase())
							) {
								this.setState({ newDoc: false });
							} else {
								this.setState({ nameError: true });
							}
						}}
					>
						Accept Name
					</button>
					<button
						onClick={(e) =>
							this.setState({ newDoc: false, docType: "" })
						}
					>
						Cancel
					</button>
				</div>
			);
		}
		if (
			!this.state.docType &&
			this.state.location !== process.env.PUBLIC_URL + "/document"
		)
			return (
				<div className="App">
					<Header logged={true}></Header>
					<div className="b1">
						<h1 className="omm-title">OMM DOCUMENTS</h1>
						<div className="b2 jsb" style={{ overflow: "visible" }}>
							{this.props.type === "admin" && (
								<button
									id="addButton"
									onClick={(e) => this.new()}
								>
									Add {window.capitalize(this.props.docType)}
								</button>
							)}
							{/* <SearchBar
								placeholder={`Search Documents`}
								indexes={[
									"name",
									"category",
									"tags",
									"content",
									"docTypes",
								]}
								items={this.state.policies
									?.filter((a) => {
										if (window.isAdmin(this.props.type))
											return a.content;
										return a;
									})
									.map((u) => ({
										...u,
										// content: this.extractContent(u.content),
									}))}
								mainKey={"_id"}
								template={(u, i) => (
									<div
										id={u._id}
										key={i}
										onClick={(e) =>
											window.redirect(
												process.env
													.PUBLIC_URL`/new-document?id=${e.target._id}`
											)
										}
									>
										{u.name || `Untitled Document`}
									</div>
								)}
							></SearchBar> */}
						</div>
						{/* <h4>Select a document type:</h4> */}
						<div className="all-document">
							{this.state.docTypes?.map((u, i) => {
								return (
									<div key={i} className="document">
										<h2
											onClick={(e) => {
												this.setState({ docType: u });
											}}
										>
											{u?.toUpperCase()}
										</h2>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			);
		return (
			<Docs
			  id={this.state.current}
			  policies={this.state.policies || []}
			  docType={this.state.docType}
			  type={this.state.type}
			  location={this.state.location}
			  queries={this.state.queries}
			/>
			// <NewDocs
			// 	id={this.state.current}
			// 	policies={this.state.policies || []}
			// 	docType={this.state.docType}
			// 	type={this.state.type}
			// 	location={this.state.location}
			// 	queries={this.state.queries}
			// />
		);
	}
}
export default withRouter(App);
