import "./secure-transfers";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
console.log("process.env.PUBLIC_URL = ", process.env.PUBLIC_URL);
window.DEV = false;
window.API = !window.DEV
	? "https://policies.octanemultimedia.com"
	// : "http://localhost:3020";
:  "https://dev14.ivantechnology.in/highoctaneandrea/backend";
window.WSURI = !window.DEV
	? "wss://ws.policies.octanemultimedia.com"
	: "ws://localhost:2800";
window.reconnect = 0;
window.lastPing = null;
window.lastPingTime = null;
window.currentPing = 0;
window.CRMS = [
	"film-sales",
	"film-distribution",
	"acquisitions",
	"corporate-sales",
];
window.adminTypes = ["admin", "dev", "manager", "accountant"];
window.internalTypes = [
	"admin",
	"sales",
	"tech",
	"dev",
	"manager",
	"accountant",
];
window.canApproveExpense = (type) => type === "accountant";
window.isSuper = (type) => ["admin", "dev"].includes(type);
window.isInternal = () => window.internalTypes.includes(window.app?.state.type);
window.isAdmin = () => window.adminTypes.includes(window.app?.state.type);
window.sendPing = () => {
	window.lastPing = Math.floor(Math.random() * 10000);
	window.lastPingTime = new Date().getTime();
	window.ws.sendData({ type: "ping", data: window.lastPing });
};
window.checkws = () => {
	if (window.ws && window.ws.readyState !== WebSocket.OPEN) {
		window.newSocket().catch((e) => {
			window.flash(`WS ERROR: ${JSON.stringify(e)}`);
		});
	} else {
		window.sendPing();
	}
};
window.checkauth = () => {
	if (
		window.ws &&
		window.ws.readyState === WebSocket.OPEN &&
		window.app.state.auth &&
		window.app.state.auth !== "undefined" &&
		window.app.state.userID &&
		window.app.state.userID !== "undefined"
	)
		window.ws.sendData({
			type: "auth",
			data: {
				method: "check",
				auth: window.app.state.auth,
				_id: window.app.state.userID,
			},
		});
};
window.runSocket = (e) => {
	let that;
	try {
		that = JSON.parse(e);
	} catch (err) {
		try {
			that = JSON.parse(e.data);
		} catch (e) {
			return console.log(`RECEIVED DATA: ${JSON.stringify(e)}`);
		}
	}
	if (that && that.type) {
		let { type, data, error } = that;
		switch (type) {
			case "auth": {
				if (error) {
					window.flash(
						that.message ||
							"Issue with authenticating you, please sign in again"
					);
					window.app.logOut();
				} else {
					window.app.setState({ authenticated: true });
				}
				break;
			}
			case "ping": {
				if (window.lastPing !== data) {
					window.sendPing();
				} else {
					window.currentPing =
						new Date().getTime() - window.lastPingTime;
					//console.log(`Current Ping: ${window.currentPing}ms`)
				}
				break;
			}
			default: {
				return console.log(`INVALID TYPE: ${e}`);
			}
		}
	}
};
window.capitalize = (string) => {
	if (typeof string !== "string") return string;
	let a = string.split("");
	if (a && a[0]) a[0] = a[0].toUpperCase();
	return a.join("");
};
window.socketWait = null;
window.waitForSocketConnection = (socket, callback, data, timer = 1000) => {
	clearTimeout(window.socketWait);
	if (!callback || typeof callback !== "function") return;
	if (socket.readyState === 0) {
		window.socketWait = setTimeout(() => {
			window.waitForSocketConnection(socket, callback, data, timer);
		}, timer);
	} else if (socket.readyState === 1) {
		callback(data);
	} else {
		window.socketWait = window.waitForSocketConnection(
			socket,
			callback,
			data,
			timer
		);
	}
};
window.waitForAuth = (socket, callback, data, timer = 1000, rounds = 0) => {
	clearTimeout(window.socketWaitForAuth);
	if (!callback || typeof callback !== "function" || rounds > 10) return;
	if (!window.app.state.authenticated) {
		window.socketWaitForAuth = setTimeout(
			() => {
				window.waitForAuth(socket, callback, data, timer);
			},
			timer,
			rounds + 1
		);
	} else {
		callback(data);
	}
};
window.newSocket = function () {
	return new Promise((res, rej) => {
		clearInterval(window.heartbeat);
		clearTimeout(window.socketWait);
		try {
			window.ws = new WebSocket(window.WSURI);
			window.ws.onopen = function (e) {
				window.heartbeat = setInterval(() => {
					window.checkws();
				}, 10000);
			};
			window.waitForSocketConnection(window.ws, () => {
				if (
					window.app?.state?.auth &&
					window.app?.state?.userID &&
					typeof window.app.state.auth !== "undefined" &&
					typeof window.app.state.userID !== "undefined"
				)
					window.ws.sendData({
						type: "auth",
						data: {
							auth: window.app.state.auth,
							userID: window.app.state.userID,
						},
					});
			});
			window.ws.onmessage = function incoming(data) {
				window.runSocket(data);
			};
			window.ws.sendData = (data) => {
				if (!window.app.state.authenticated && data.type !== "auth") {
					window.waitForAuth(
						window.ws,
						(data) => {
							window.ws.send(JSON.stringify(data));
						},
						data
					);
				} else if (window.ws.readyState !== 1) {
					window.waitForSocketConnection(
						window.ws,
						(data) => {
							window.ws.send(JSON.stringify(data));
						},
						data
					);
				} else {
					window.ws.send(JSON.stringify(data));
				}
			};
			window.ws.onclose = () => window.newSocket();
			return res(true);
		} catch {
			if (window.reconnect <= 10) {
				setTimeout(() => {
					window.reconnect++;
					window.newSocket();
				}, window.reconnect * 3000);
			} else {
				return rej(
					"Error reloading websocket. must be an issue on the backend."
				);
			}
		}
	});
};
window.redirect = (path) => window.app.props.history.push(path);
window.flash = (e, time, clr) => {
	var node = document.createElement("div");
	node.className = "snackbar show";
	node.style.backgroundColor = clr || "#333";
	node.innerHTML = e;
	document.querySelector("body").append(node);
	setTimeout(function () {
		node.remove();
	}, time || 5000);
};
window.territories = [
	"Africa",
	"Australia / NZ",
	"Baltics",
	"Benelux",
	"Brazil",
	"Brunei",
	"Bulgaria",
	"Cambodia",
	"Central Asia",
	"China",
	"CIS",
	"Czech Rep",
	"East Europe",
	"Yugoslavia",
	"France",
	"Germany",
	"Greece",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Israel",
	"Italy",
	"Japan",
	"Latin America",
	"Malaysia",
	"Middle East",
	"Mexico",
	"Mongolia",
	"Pakistan",
	"Pan Asia",
	"Peru",
	"Philippines",
	"Poland",
	"Portugal",
	"Scandinavia",
	"Singapore",
	"South Africa",
	"South Korea",
	"Southeast Asia",
	"Spain",
	"Taiwan",
	"Thailand",
	"Turkey",
	"Ukraine",
	"UAE",
	"UK",
	"USA",
	"Vietnam",
];
window.index = (ar, page, s) => {
	let a = [];
	if (ar.length <= s) return ar;
	if (ar.length > s) {
		if (ar.length - s * page >= 0) {
			for (let i = s * page; i < s * (page + 1); i++)
				if (ar[i]) a.push(ar[i]);
		} else {
			for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++)
				if (ar[z]) a.push(ar[z]);
		}
	}
	return a;
};
window.formatDate = (date) => {
	let d = date && typeof date.getTime === "function" ? date : new Date(date);
	let month = d.getMonth() + 1;
	let day = d.getDate();
	return `${month < 10 ? "0" + month : month}-${
		day < 10 ? "0" + day : day
	}-${d.getFullYear()}`;
};
window.parentClick = (e) => {
	e.preventDefault();
	e.stopPropagation();
	e.target.parentElement?.click();
};
ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);
