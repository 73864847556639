import { Route } from "react-router-dom";
import { Component } from "react";
// import SearchBar from "./helper/searchbar";
import { TextEditor, Preview } from "./texteditor";
import Header from "./header";
import PDF_ICON from "./pdf.svg";
import VIDEO_ICON from "./video.svg";
import LIST_ICON from "./list.png";
import GRID_ICON from "./grid.jpg";
class Docs extends Component {
	state = {
		document: "",
		name: "",
		current: "",
		edit: false,
		toggle: false,
		draft: false,
		published: false,
		gotPolicies: false,
		stored: null,
		categories: [],
		namefilter: null,
		filteredCats: [],
		filteredTags: [],
		taglist: [],
		docTypes: [],
		category: "",
		tags: [],
		file_url: null,
		autosave: localStorage.getItem("autosave") === "true" ? true : false,
		isLoading: false,
		isDraftLoading: false,
		isRevertDraftLoading: false,
		document_type: "pdf",
		view_type: "grid",
	};
	componentWillMount() {
		window.docs = this;
	}
	async componentDidMount() {
		// console.log(this.props.queries);
		if (!this.props.docType)
			if (
				this.props.policies?.length > 0 &&
				(this.props.location === process.env.PUBLIC_URL + "/" ||
					this.props.location ===
						process.env.PUBLIC_URL + "/document")
			) {
				if (
					!this.state.current &&
					this.props.queries.find((u) => u.key === "?id")
				) {
					let id = this.props.queries.find(
						(u) => u.key === "?id"
					).value;
					let policy = this.props.policies.find((u) => u._id === id);
					if (policy) {
						let {
							content,
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							docTypes = [],
							document_type,
							file_name
						} = policy;
						let file_url =
							window.API + "/public/pdf/" + policy?.file_name;
						let docType =
							this.props.docType ||
							(docTypes?.length > 0 ? docTypes[0] : null) ||
							"policy";
						if (!this.props.docType)
							window.app.setState({ docType: docType });
						if (!docTypes?.length > 0) docTypes = [docType];
						this.setState({
							current: policy._id,
							name,
							document:
								this.state.edit && draft
									? draftContent
									: content,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							docTypes,
							file_url,
							document_type,
							file_name
						});
					} else {
						window.redirect(process.env.PUBLIC_URL + "/");
					}
				}
			}
		if (!this.state.gotTags && !this.gettingTags)
			this.getTaglist().catch((e) => window.flash(e));
		if (!this.state.gotCategories && !this.gettingCategories)
			this.getCategories().catch((e) => window.flash(e));
	}
	setTypes = (types) => {
		this.setState({ docTypes: types });
	};
	componentDidUpdate() {
		// console.log(this.props.queries);
		if (
			this.props.location === process.env.PUBLIC_URL + "/document" &&
			this.props.policies?.length > 0 &&
			!this.state.current &&
			this.props.queries.find((u) => u.key === "?id")
		) {
			let id = this.props.queries.find((u) => u.key === "?id").value;
			let policy = this.props.policies.find((u) => u._id === id);
			if (policy) {
				let {
					content,
					name,
					draftContent,
					draft,
					published,
					created,
					modified,
					category,
					tags,
					docTypes = [],
					document_type,
					file_name
				} = policy;
				let file_url = window.API + "/public/pdf/" + policy?.file_name;
				let docType =
					this.props.docType ||
					(docTypes?.length > 0 ? docTypes[0] : null) ||
					"policy";
				if (!this.props.docType)
					window.app.setState({ docType: docType });
				if (!docTypes?.length > 0) docTypes = [docType];
				this.setState({
					current: policy._id,
					name,
					document: this.state.edit && draft ? draftContent : content,
					draft,
					published,
					created,
					modified,
					category,
					tags,
					docTypes,
					file_name,
					file_url,
					document_type,
				});
			} else {
				window.redirect(process.env.PUBLIC_URL + "/");
			}
		}
		if (!this.state.gotTags && !this.gettingTags)
			this.getTaglist().catch((e) => window.flash(e));
		if (!this.state.gotCategories && !this.gettingCategories)
			this.getCategories().catch((e) => window.flash(e));
	}
	getTaglist = () => {
		return new Promise((res, rej) => {
			this.gettingTags = true;
			window.app
				.request("/taglist", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingTags = false;
					let { tags } = r;
					this.setState({ taglist: tags, gotTags: true });
					res(tags);
				})
				.catch((e) => {
					this.gettingTags = false;
					rej(e);
				});
		});
	};
	getCategories = () => {
		return new Promise((res, rej) => {
			this.gettingCategories = true;
			window.app
				.request("/categories", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingCategories = false;
					let { categories = [] } = r;
					this.setState({
						categories: [...categories],
						gotCategories: true,
					});
					res(categories);
				})
				.catch((e) => {
					this.gettingCategories = false;
					rej(e);
				});
		});
	};
	getPolicies = () => {
		return new Promise((res) => {
			this.setState(
				{
					policies: window.app.state.policies?.filter(
						(a) => a.docTypes.includes(this.props.docType) || []
					),
					gotPolicies: true,
				},
				() => res()
			);
		});
	};
	savePolicy = (policy) => {
		return new Promise((res, rej) => {
			if (!policy) return rej("NO POLICY PASSED");
			if (!policy?.name) return rej("Document title is missing");
			this.saving = true;
			fetch(window.API + "/save-policy", {
				method: "post",
				headers: {
					authorization: window.app.state.auth,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					policy: {
						...policy,
						docTypes:
							this.state.docTypes?.length < 1
								? [this.props.docType]
								: [...this.state.docTypes]
										.reduce((a, b) => {
											if (!a.includes(b)) a.push(b);
											return a;
										}, [])
										.filter((a) => a),
					},
				}),
			})
				.then((r) => r.json())
				.then((r) => {
					this.saving = false;
					if (r.error) {
						if (r.message && /not authorized/.test(r.message)) {
							window.flash(
								"Issue authenticating you, please log in again."
							);
							window.app.logOut();
						} else {
							return rej(r.message || JSON.stringify(r));
						}
					}
					let { policy } = r;
					window.flash(
						`${policy.draft ? "Draft" : "Document"} Saved`
					);
					window.app
						.getPolicies()
						.then((r) => {
							this.getPolicies()
								.then(() => {
									res(policy);
								})
								.catch((e) => rej(e));
						})
						.catch((e) => rej(e));
				})
				.catch((e) => rej(e));
		});
	};
	new = () => {
		this.setState({ current: "", document: "", name: "", edit: true }, () =>
			window.redirect(process.env.PUBLIC_URL + "/document")
		);
	};
	set = (id) => {
		let policy = window.app.state.policies?.find((u) => u._id === id);
		if (!policy) return window.flash("Unable to load document" + id);
		let {
			file_name,
			content,
			name,
			draftContent,
			draft,
			published,
			created,
			modified,
			category,
			tags,
			docTypes = [],
			document_type,
		} = policy;
		let file_url = window.API + "/public/pdf/" + policy?.file_name;
		let docType =
			this.props.docType ||
			(docTypes?.length > 0 ? docTypes[0] : null) ||
			"policy";
		if (!this.props.docType) window.app.setState({ docType: docType });
		if (!docTypes?.length > 0) docTypes = [docType];
		if (window.app.state.auth && draft) {
			this.setState(
				{
					current: id,
					document: draftContent,
					draft: true,
					published,
					category,
					tags,
					name,
					edit: true,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() =>
					window.redirect(
						process.env.PUBLIC_URL + `/document?id=${id}`
					)
			);
		} else if (
			!window.app.state.auth ||
			window.app.state.type !== "admin"
		) {
			window.docs.setState(
				{
					current: id,
					document: content,
					name,
					category,
					tags,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() => {
					// window.redirect(process.env.PUBLIC_URL + `/document?id=${id}`)
					if (file_name) {
						window.open(`${file_url}`, "_blank");
					} else {
						window.redirect(process.env.PUBLIC_URL + `/`);
					}
				}
			);
		} else if (window.app.state.auth) {
			this.setState(
				{
					current: id,
					edit: false,
					document: content,
					draft: false,
					published,
					category,
					tags,
					name,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() =>
					window.redirect(
						process.env.PUBLIC_URL + `/document?id=${id}`
					)
			);
		}
	};
	edit = () => {
		this.setState({ edit: !this.state.edit }, () => {
			if (this.state.edit === false) {
				this.getPolicies().catch((e) => window.flash(e));
			} else if (this.state.edit === true && this.state.toggle) {
				let policy = this.props.policies.find(
					(u) => u._id === this.state.current
				);
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft
						? content
						: this.state.stored
						? this.state.stored
						: draftContent;
					this.setState(
						{
							toggle: !this.state.toggle,
							draft: !this.state.draft,
							document,
						},
						() => {
							if (window.editor)
								window.editor.setState({
									editor: window.editor.init(),
								});
						}
					);
				}
			} else if (this.state.edit === true) {
				let policy = this.props.policies.find(
					(u) => u._id === this.state.current
				);
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft ? content : draftContent;
					this.setState({ draft, document }, () => {
						if (window.editor)
							window.editor.setState({
								editor: window.editor.init(),
							});
					});
				}
			}
		});
	};
	delete = () => {
		window.app
			.request("/delete-policy", "post", { _id: this.state.current })
			.then((r) => {
				window.flash("Deleted Document");
				window.app
					.getPolicies()
					.then(() => {
						window.redirect(process.env.PUBLIC_URL + "/");
					})
					.catch((e) => window.flash(e));
			})
			.catch((e) => window.flash(e));
	};
	setDraft = (revert) => {
		let id = window.docs.state.current;
		if (revert) {
			if (!id) {
				this.new();
			} else {
				let policy = window.app.state.policies?.find(
					(u) => u._id === id
				);
				if (policy && policy.published) {
					policy.draftContent = policy.content;
					this.setState({ isRevertDraftLoading: true });
					this.savePolicy(policy)
						.then((policy) => {
							console.log(policy);
							this.setState({ isRevertDraftLoading: false });
							let {
								name,
								draftContent,
								draft,
								published,
								created,
								modified,
								category,
								tags,
								document_type,
							} = policy;
							this.setState(
								{
									current: policy._id,
									name,
									document: draftContent,
									draft,
									published,
									created,
									modified,
									category,
									tags,
									document_type,
								},
								() => {
									if (window.editor)
										window.editor.setState({
											editor: window.editor.init(),
										});
								}
							);
						})
						.catch((e) => {
							this.setState({ isRevertDraftLoading: false });
							window.flash(e);
						});
				} else {
					this.setState({ document: null });
				}
			}
		} else {
			if (!id) {
				let policy = {
					name: this.state.name,
					content: this.state.document,
					draftContent: this.state.document,
					published: false,
					draft: true,
				};
				this.setState({ isDraftLoading: true });
				this.savePolicy(policy)
					.then((policy) => {
						this.setState({ isDraftLoading: false });
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						} = policy;
						this.setState({
							current: policy._id,
							name,
							document: draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						});
					})
					.catch((e) => {
						this.setState({ isDraftLoading: false });
						window.flash(e);
					});
			} else {
				let policy = window.app.state.policies?.find(
					(u) => u._id === id
				);
				if (!policy.draft) policy.draft = true;
				policy.draftContent = this.state.document;
				this.setState({ isDraftLoading: true });
				this.savePolicy(policy)
					.then((policy) => {
						this.setState({ isDraftLoading: false });
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						} = policy;
						this.setState({
							current: policy._id,
							name,
							document: draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						});
					})
					.catch((e) => {
						this.setState({ isDraftLoading: false });
						window.flash(e);
					});
			}
		}
	};
	publish() {
		let id = window.docs.state.current;
		if (id) {
			let policy = window.app.state.policies?.find((u) => u._id === id);
			if (!id) return window.flash("Issue publishing policy");
			policy.name = window.docs.state.name;
			policy.draft = false;
			policy.published = true;
			policy.modified = new Date();
			policy.content = window.docs.state.document;
			policy.draftContent = "";
			policy.tags = window.docs.state.tags;
			policy.category = window.docs.state.category;
			policy.document_type = window.docs.state.document_type;
			this.setState({ isLoading: true });
			window.docs
				.savePolicy(policy)
				.then((policy) => {
					this.setState({ isLoading: false });
					let {
						name,
						content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					} = policy;
					let state = {
						current: policy._id,
						name,
						document: content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					};
					// if (!this.state.categories.includes(category))
					// 	state.categories = [...this.state.categories, category];
					window.docs.setState(state);
				})
				.catch((e) => {
					this.setState({ isLoading: false });
					window.flash(e);
				});
		} else {
			let policy = {
				name: window.docs.state.name,
				content: window.docs.state.document,
				published: true,
				draft: false,
				tags: window.docs.state.tags,
				category: window.docs.state.category,
				document_type: window.docs.state.document_type,
			};
			window.docs
				.savePolicy(policy)
				.then((policy) => {
					this.setState({ isLoading: false });
					let {
						name,
						content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					} = policy;
					let state = {
						current: policy._id,
						name,
						document: content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					};
					// if (!this.state.categories.includes(category))
					// 	state.categories = [...this.state.categories, category];
					window.docs.setState(state);
				})
				.catch((e) => {
					this.setState({ isLoading: false });
					window.flash(e);
				});
		}
	}
	toggleDoc = () => {
		let { current, draft } = this.state;
		if (current) {
			let policy = this.props.policies.find((u) => u._id === current);
			if (policy) {
				if (draft) {
					this.setState(
						{
							edit: false,
							draft: false,
							stored: this.state.document,
							document: policy.content,
							toggle: !this.state.toggle,
						},
						() => {
							if (window.editor)
								window.editor.setState({ preview: true });
						}
					);
				} else {
					this.setState({
						edit: false,
						draft: true,
						document: this.state.stored || policy.draftContent,
						toggle: !this.state.toggle,
					});
				}
			}
		}
	};
	addTag = (tag) => {
		this.setState({
			tags: [...this.state.tags, tag].reduce((a, b) => {
				if (!a.includes(b)) a.push(b);
				return a;
			}, []),
		});
	};
	removeTag = (tag) => {
		this.setState({ tags: [...this.state.tags].filter((u) => u !== tag) });
	};
	changeCategory = (category) => {
		this.setState({ category });
	};
	halfA(array, half) {
		let a = [];
		if (!array) return a;
		for (let i = half === 1 ? 0 : 1; i < array.length; i += 2) {
			a.push(array[i]);
		}
		return a;
	}
	divideArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) o[i] = [];
		let c = 0;
		return Object.entries(
			array.reduce((a, b) => {
				if (c < parts) {
					a[c].push(b);
				} else {
					c = 0;
					a[c].push(b);
				}
				c++;
				return a;
			}, o)
		).map((u) => u[1]);
	}
	splitArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) {
			o[i] = [];
			let dec = Math.floor((array.length / parts) * i);
			let inc = Math.floor((array.length / parts) * (i + 1));
			for (let z = dec; z < inc && z < array.length; z++) {
				if (array[z]) o[i].push(array[z]);
			}
		}
		return Object.entries(o).reduce((a, b) => {
			a.push(b[1]);
			return a;
		}, []);
	}
	nameSort(array) {
		return array.sort((a, b) =>
			a.name < b.name ? -1 : a.name === b.name ? 0 : 1
		);
	}
	handleSave = () => {
		clearTimeout(this.save);
		this.save = setTimeout(() => {
			if (this.saving) return;
			let policy = this.props.policies.find(
				(u) => u._id === this.state.current
			);
			if (policy) {
				let { draft, published, content, draftContent } = policy;
				this.savePolicy({
					name: this.state.name,
					content: draft ? content : this.state.document,
					draftContent:
						this.state.draft && this.state.edit
							? this.state.document
							: draftContent,
					published,
					draft,
					tags: this.state.tags,
					category: this.state.category,
					_id: this.state.current,
				})
					.then((policy) => {
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
						} = policy;
						this.setState(
							{
								current: policy._id,
								name,
								document: draftContent,
								draft,
								published,
								created,
								modified,
								category,
								tags,
							},
							() => {
								if (window.editor)
									window.editor.setState({
										editor: window.editor.init(),
									});
							}
						);
					})
					.catch((e) => window.flash(e));
			}
		}, 2150);
	};
	uploadImage(e) {
		return new Promise((res, rej) => {
			let data = new FormData();
			data.append("image", e);
			let that = new XMLHttpRequest();
			that.onerror = (e) => {
				window.flash(e);
				rej();
			};
			that.onabout = (e) => {
				window.flash(e);
				rej();
			};
			that.onreadystatechange = () => {
				if (that.readyState === 4) {
					let d = JSON.parse(that.responseText);
					if (d.error) {
						window.flash(d.message);
						return rej();
					}
					res({ data: { link: d.link } });
				}
			};
			that.open("POST", window.API + "/upload", true);
			that.setRequestHeader("authorization", window.app.state.auth);
			that.send(data);
		});
	}
	extractContent(text) {
		try {
			return JSON.parse(text)
				.blocks.map((u) => u.text)
				.join(" ");
		} catch (e) {
			console.log(e);
			return "";
		}
	}
	documentSearch(event) {
		const newValue = event.target.value;
		if (newValue) {
			window.app.setState({
				namefilter: newValue,
			});
		} else {
			window.app.setState({
				namefilter: null,
			});
		}
		// console.log(window.app.state.namefilter);
	}
	toogleCategory(event) {
		const newValue = event.target.value;
		if (newValue !== "all") {
			window.app.setState({
				filteredCats: newValue,
			});
		} else {
			window.app.setState({
				filteredCats: [],
			});
		}
	}
	render() {
		window.docs = this;
		return (
			<div className="App">
				<Header autosave={this.state.autosave} logged={true}></Header>
				<Route exact path={process.env.PUBLIC_URL + "/"}>
					<div className="w-100">
						<div className="doc-title">
							<div className="d-flex">
								<h1>
									{this.props?.docType?.toUpperCase()}{" "}
									DOCUMENTS
								</h1>
								<div className="view-toogle d-none">
									<img
										onClick={(e) => {
											let view_type =
												window.app.state.view_type;
											switch (view_type) {
												case "grid":
													view_type = "list";
													break;

												default:
													view_type = "grid";
													break;
											}
											window.app.setState({
												view_type: view_type,
											});
										}}
										className="icon"
										src={
											window.app.state.view_type ===
											"grid"
												? GRID_ICON
												: LIST_ICON
										}
										alt="icon"
									/>
									{/* <button
										className={
											window.app.state.view_type ===
											"grid"
												? ""
												: "active"
										}
										onClick={(e) => {
											window.app.setState({
												view_type: "grid",
											});
										}}
									>
										GRID
									</button> */}
								</div>
							</div>
							<p>
								{this.props?.docType?.toUpperCase() === "POLICY"
									? "These company policies are guidelines to help the employer, employee and contractor know their expectations with the company."
									: this.props?.docType?.toUpperCase() ===
									  "SOP"
									? "These company standard operating procedures provide detailed instructions for specific tasks to streamline processes and achieve maximum efficiency."
									: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"}
							</p>
						</div>
						<div className="search-filter">
							<div className="category">
								<label htmlFor="categories">Category</label>
								<select
									onChange={this.toogleCategory}
									name="categories"
									id="categories"
								>
									<option value="all">All</option>
									{this.state.categories &&
										this.state.categories.map((u, i) => (
											<option
												selected={window.app.state.filteredCats.includes(
													u
												)}
												value={u}
												key={i}
											>
												{u.toUpperCase()}
											</option>
										))}
								</select>
							</div>
							<div className="mobsearch">
								<label for="search" className="catserch">
									search
								</label>
								<input
									onChange={this.documentSearch}
									type="text"
									placeholder="Search"
									id="searchkey"
									name="searchkey"
								></input>
							</div>
						</div>
						<div className="list-inner">
							<div className="list-wrapper">
								<div className="list-row" id="policy-list">
									{this.nameSort(
										(this.props.type === "admin"
											? this.props.policies
											: this.props.policies.filter(
													(u) => u.published
											  )
										).filter(
											(a) =>
												a.docTypes.includes(
													this.props.docType
												) &&
												(window.app.state.filteredCats
													?.length > 0
													? window.app.state.filteredCats.includes(
															a.category
													  )
													: true) &&
												(window.app.state.namefilter
													? a.content
															.toLowerCase()
															.includes(
																window.app.state.namefilter
																	.trim()
																	.toLowerCase()
															) ||
													  a.name
															.toLowerCase()
															.includes(
																window.app.state.namefilter
																	.trim()
																	.toLowerCase()
															)
													: true)
										)
									).map((u, i) => {
										if (!u) return null;
										return (
											<div
												className={
													window.app.state
														.view_type === "grid"
														? "p-box"
														: "p-list"
												}
											>
												<div
													className="policy-item"
													key={i}
													id={u._id}
													onClick={(e) => {
														this.set(u._id);
													}}
												>
													<div className="img">
														<img
															src={
																u?.document_type ===
																"video"
																	? VIDEO_ICON
																	: PDF_ICON
															}
															alt="icon"
														/>
													</div>
													<span>
														{u.name ||
															`Untitled ${window.capitalize(
																this.props
																	.docType
															)}`}
													</span>
												</div>
											</div>
										);
									})}
									{!(
										this.props.type === "admin"
											? this.props.policies
											: this.props.policies.filter(
													(u) => u.published
											  )
									).filter(
										(a) =>
											a.docTypes.includes(
												this.props.docType
											) &&
											(window.app.state.filteredCats
												?.length > 0
												? window.app.state.filteredCats.includes(
														a.category
												  )
												: true) &&
											(window.app.state.namefilter
												? a.content
														.toLowerCase()
														.includes(
															window.app.state.namefilter
																.trim()
																.toLowerCase()
														) ||
												  a.name
														.toLowerCase()
														.includes(
															window.app.state.namefilter
																.trim()
																.toLowerCase()
														)
												: true)
									).length && (
										<div className="no-items">
											<span>NO ITEMS FOUND</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{/* <div
						className="b1"
						style={{
							justifyContent: "flex-start",
							overflowY: "auto",
						}}
					>
						<h3>
							{window.capitalize(this.props.docType)} Documents
						</h3>
						<div className="b2 jsb" style={{ overflow: "visible" }}>
							<button
								onClick={() =>
									this.setState({
										showFilters: !this.state.showFilters,
									})
								}
							>
								{this.state.showFilters ? "Hide" : "Show"}{" "}
								Filters
							</button>
							{this.props.type === "admin" && (
								<button
									id="addButton"
									onClick={(e) => this.new()}
								>
									Add {window.capitalize(this.props.docType)}
								</button>
							)}
							<SearchBar
								placeholder={`Search ${window.capitalize(
									this.props.docType
								)}`}
								indexes={[
									"name",
									"category",
									"tags",
									"content",
									"docTypes",
								]}
								items={this.props.policies
									.filter((a) => {
										if (this.props.type !== "admin")
											return a.content;
										return a.docTypes.includes(
											this.props.docType
										);
									})
									.map((u) => ({
										...u,
										content: u.content
											? this.extractContent(u.content)
											: "",
									}))}
								mainKey={"_id"}
								template={(u, i) => (
									<div
										id={u._id}
										key={i}
										onClick={(e) => this.set(e.target.id)}
									>
										{u.name ||
											`Untitled ${window.capitalize(
												this.props.docType
											)}`}
									</div>
								)}
							></SearchBar>
						</div>
						<div
							style={{
								maxWidth: "80%",
								width: "80%",
								display: "grid",
								gridTemplateColumns: this.state.showFilters
									? "1fr 10fr"
									: "1fr",
							}}
						>
							{this.state.showFilters && (
								<div className="b1">
									<h3>Show Only</h3>
									{this.state.categories?.length > 0 && (
										<div className="b1">
											<h4>Categories</h4>
											<ul>
												{this.state.categories?.map(
													(u, i) =>
														this.state.filteredCats.includes(
															u
														) ? (
															<li
																onClick={() => {
																	this.setState(
																		{
																			filteredCats:
																				[
																					...this
																						.state
																						.filteredCats,
																				].filter(
																					(
																						a
																					) =>
																						a !==
																						u
																				),
																		}
																	);
																}}
																className={
																	"selectedFilter"
																}
															>
																{u}
															</li>
														) : (
															<li
																key={i}
																className={
																	"unselectedFilter"
																}
																onClick={() => {
																	this.setState(
																		{
																			filteredCats:
																				[
																					...this
																						.state
																						.filteredCats,
																					u,
																				],
																		}
																	);
																}}
															>
																{u}
															</li>
														)
												)}
											</ul>
										</div>
									)}
									{this.state.tagslist?.length > 0 && (
										<div className="b1">
											<h4>Tags</h4>
											<ul>
												{this.state.taglist.map(
													(u, i) =>
														this.state.filteredTags.includes(
															u
														) ? (
															<li
																onClick={() => {
																	this.setState(
																		{
																			filteredTags:
																				[
																					...this
																						.state
																						.filteredTags,
																				].filter(
																					(
																						a
																					) =>
																						a !==
																						u
																				),
																		}
																	);
																}}
																className={
																	"selectedFilter"
																}
															>
																{u}
															</li>
														) : (
															<li
																key={i}
																className={
																	"unselectedFilter"
																}
																onClick={() => {
																	this.setState(
																		{
																			filteredTags:
																				[
																					...this
																						.state
																						.filteredTags,
																					u,
																				],
																		}
																	);
																}}
															>
																{u}
															</li>
														)
												)}
											</ul>
										</div>
									)}
								</div>
							)}
							<div
								id="homepageList"
								className="c3"
								style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
							>
								{this.splitArray(
									this.nameSort(
										(this.props.type === "admin"
											? this.props.policies
											: this.props.policies.filter(
													(u) => u.published
											  )
										).filter(
											(a) =>
												a.docTypes.includes(
													this.props.docType
												) &&
												(this.state.filteredCats
													?.length > 0
													? this.state.filteredCats.includes(
															a.category
													  )
													: true) &&
												(this.state.filteredTags
													?.length > 0
													? this.state.filteredTags.reduce(
															(z, b) => {
																if (!z) {
																	return a.tags.includes(
																		b
																	);
																} else {
																	return z;
																}
															},
															false
													  )
													: true)
										)
									),
									3
								).map((u, i) => (
									<ul key={i} className="policylist">
										{u.map((u, i) => {
											if (!u) return null;
											return (
												<li
													key={i}
													id={u._id}
													onClick={(e) => {
														this.set(e.target.id);
													}}
												>
													{u.name ||
														`Untitled ${window.capitalize(
															this.props.docType
														)}`}
												</li>
											);
										})}
									</ul>
								))}
							</div>
						</div>
					</div> */}
				</Route>
				<Route exact path={process.env.PUBLIC_URL + "/document"}>
					{!this.state.edit && !this.state.toggle ? (
						<Preview
							docType={this.props.docType}
							docTypes={this.state.docTypes}
							category={this.state.category}
							tags={this.state.tags}
							file_url={this.state.file_url}
							draft={this.state.draft}
							created={this.state.created}
							modified={this.state.modified}
							file_name={this.state.file_name}
							edit={() => this.edit()}
							delete={() => this.delete(this.state.current)}
							document={this.state.document}
							name={this.state.name}
						/>
					) : (
						<TextEditor
							categories={this.state.categories}
							taglist={this.state.taglist}
							category={this.state.category}
							tags={this.state.tags}
							document={this.state.document}
							name={this.state.name}
							draft={this.state.draft}
							published={this.state.published}
							created={this.state.created}
							modified={this.state.modified}
							file_name={this.state.file_name}
							callback={(state) => {
								this.setState({ document: state }, async () => {
									if (
										this.state.name &&
										this.state.autosave &&
										this.state.current
									) {
										if (!this.state.draft && !this.saving)
											await this.setDraft();
										this.handleSave();
									}
								});
							}}
							docType={this.props.docType}
							docTypes={this.state.docTypes}
							setName={(e) => this.setState({ name: e })}
						></TextEditor>
					)}
				</Route>
			</div>
		);
	}
}
export default Docs;
