export default function Login() {
  return (
    <div className="b1 jfs" style={{ margin: "0" }}>
      <form
        className="b1 login"
        onSubmit={(e) => {
          e.preventDefault();
          let form = e.target;
          fetch(window.API + "/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: form["email"].value,
              password: form["password"].value,
            }),
          })
            .then((result) => result.json())
            .then((result) => {
              if (result.error)
                return window.flash(result.message || result, 5000, "ef0000");
              form.reset();
              localStorage.setItem("userID", result.userID);
              localStorage.setItem("type", result.type);
              localStorage.setItem("auth", result.auth);
              window.flash("success", 5000, "ef0000");
              console.log(window.app);

              // code we have added
              window.app.setState({
                ...window.app.state,
                auth: result.auth,
                type: result.type,
                userid: result.userID,
              });
              window.app.getPolicies();
              window.redirect(process.env.PUBLIC_URL+"/");

              // previous code
              // if (window.DESKTOP) {
              //   window.DESKTOP.getCookie(result.redirect)
              //     .then(() => {
              //       window.app.setState({
              //         auth: localStorage.getItem("auth"),
              //         type: localStorage.getItem("type"),
              //         userID: localStorage.getItem("userID"),
              //       });
              //       window.app.getPolicies();
              //       window.redirect("/");
              //     })
              //     .catch((e) => {
              //       window.flash(e);
              //       localStorage.clear();
              //     });
              // } else {
              //   // console.log("exit");
              //   // return;
              //   window.location = result.redirect;
              // }
            })
            .catch((e) => {
              console.log(e);
              window.flash("Error processing your request.", 5000, "ef0000");
            });
        }}
      >
        <br />
        <h2>OMM Login</h2>
        <div className="b1 loginContCont">
          <div className="b1 loginCont">
            <input
              type="email"
              name="email"
              placeholder="Email"
            ></input>
          </div>
          <div
            className="b1 loginCont"
            style={{ width: "60%", margin: "5px 15px" }}
          >
            <input
              type="password"
              name="password"
              placeholder="Password"
            ></input>
          </div>
        </div>
        <div className="b1 loginCont">
          <button type="submit">Log In</button>
        </div>
      </form>
      <a href={process.env.PUBLIC_URL+"/reset-password"}>
        <span style={{ cursor: "pointer" }}>
          Forgot your password? Click here.
        </span>
      </a>
    </div>
  );
}
