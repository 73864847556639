import "./spinner.css";

function Spinner() {
  return (
    <div
      style={{
        height: "50px",
        maxWidth: "50px",
        width: "50px",
        verticalAlign: "middle",
        display: "table-cell",
        margin: "10px",
      }}
    >
      <div id="spinner"></div>
    </div>
  );
}
export default Spinner;
