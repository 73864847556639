import React, { Component } from 'react'
class SearchInput extends Component {
    state = {
        list: [],
        searching: '',
        isBlurred: true
    }
    componentDidMount() {
        this.setState({ searching: this.props.value || '' })
    }
    componentDidUpdate(old) {
        if (this.props.value !== this.state.searching && old.value !== this.props.value) this.setState({searching: this.props.value})
        if (this.props.mainKey !== this.state.key) this.setState({ key: this.props.mainKey })
    }
    search(value) {
        if (!this.props.items || this.props.items.length < 1) return []
        let a = []
        let test = string => value && new RegExp(value, 'i').test(string) ? true : false
        for (let i = 0; i < this.props.items.length; i++) {
            let item = this.props.items[i]
            let result = test(item)
            if (result) a.push(item)
        }
        return a
    }
    handleChange = e => {
        if (typeof this.props.change === 'function') this.props.change(e)
    }
    list = (u, i) => {
        return (<div key={i} className="b2 jsb searchResult">
            <button data-value={u} className="smallbut" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                let value = e.target.getAttribute(['data-value'])
                this.setState({ searching: this.props.multiple ? '' : value, isBlurred: true }, () => this.handleChange(value))
            }}>{window.capitalize(u)}</button></div>)
    }
    render() {
        return (<div id="searchinput" onMouseLeave={e => {
            this.setState({isBlurred: false})
        }} >
            <input onBlur={e => {
                if (!e.target.value) {
                     this.setState({isBlurred: true, searching: this.props.multiple ? '' : e.target.value})
                }
            }} className="form-control" id="searchbarInput" placeholder={this.props.placeholder || "Search"} onKeyDown={e => {
                 if (e.key === 'Enter' || e.keyCode === 13) { 
                    e.preventDefault()
                    e.stopPropagation()
                 }
            }} onKeyUp={e => {
                e.preventDefault()
                e.stopPropagation()
                let {value} = e.currentTarget
                if (e.key === 'Enter' || e.keyCode === 13) { this.setState({ searching: this.props.multiple ? '' : value, isBlurred: false }, () => {if (this.props.multiple) this.handleChange(value)}) }
            }} type="search" autoComplete="off" name={this.props.name} value={this.state.searching} onChange={(e) => {
                this.setState({ searching: e.target.value, list: this.search(e.target.value), isBlurred: false }, () => { if (!this.props.multiple) this.handleChange(e.target.value) })
            }}></input>
          {!this.state.isBlurred &&  <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                {this.state.list?.length < 1 ? (<></>) : <>{this.state.list?.map(this.props.template || this.list)}</>}
            </div>}
        </div>)
    }
}
export default SearchInput