import { Component } from "react";
import PropTypes from "prop-types";
import {
	EditorState,
	convertFromRaw,
	convertToRaw,
	Modifier,
	AtomicBlockUtils,
	ContentState,
	convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Toggle from "./helper/toggle";
import ArrayInput from "./arrayinput";
import SearchInput from "./helper/searchinput";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const Preview = (props) => {
	// console.log(props);
	let doc = props.document
		? EditorState.createWithContent(
				convertFromRaw(JSON.parse(props.document))
		  )
		: EditorState.createEmpty();
	return (
		<>
			<div id="dochead">
				<div id="over">
					<h1>{props.name || "Untitled Document"}</h1>
					{window.app.state.auth && !props.preview && (
						<div id="buttons">
							<button
								onClick={(e) => {
									if (typeof props.edit === "function")
										props.edit();
								}}
							>
								Edit
							</button>
							{props?.created && (
								<button
									onClick={(e) => {
										if (typeof props.delete === "function")
											props.delete();
									}}
								>
									Delete
								</button>
							)}
							{props?.file_name && props?.created && (
								<button
									onClick={(e) => {
										window.open(
											`${props.file_url}`,
											"popupWindow",
											"scrollbars=yes"
										);
									}}
								>
									Preview PDF
								</button>
							)}
						</div>
					)}
					{props.created && (
						<span>
							Created: {window.formatDate(props.created)}
							{props.modified &&
								` | Modified: ${window.formatDate(
									props.modified
								)}`}
						</span>
					)}
				</div>
			</div>
			<Editor readOnly={true} editorState={doc} toolbarHidden></Editor>
		</>
	);
};
function DocumentHTML(props) {
	return (
		<>
			{draftToHtml(
				props.document
					? JSON.parse(props.document)
					: EditorState.createEmpty().getCurrentContent()
			)}{" "}
		</>
	);
}
class CustomOption1 extends Component {
	static propTypes = {
		onChange: PropTypes.func,
		editorState: PropTypes.object,
	};

	addStar = () => {
		const { editorState, onChange } = this.props;
		var selectionState = editorState.getSelection();
		var anchorKey = selectionState.getAnchorKey();
		var currentContent = editorState.getCurrentContent();
		var currentContentBlock = currentContent.getBlockForKey(anchorKey);
		var start = selectionState.getStartOffset();
		var end = selectionState.getEndOffset();
		var selectedText = currentContentBlock.getText().slice(start, end);
		console.log(selectedText);
		const contentState = Modifier.replaceText(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			selectedText.toUpperCase(),
			editorState.getCurrentInlineStyle()
		);
		onChange(
			EditorState.push(editorState, contentState, "insert-characters")
		);
	};
	render() {
		return (
			<div
				className="rdw-option-wrapper"
				title="Change Case"
				onClick={this.addStar}
			>
				<span style={{ color: "var(--d)" }}>TT</span>
			</div>
		);
	}
}
class CustomOption2 extends Component {
	static propTypes = {
		onChange: PropTypes.func,
		editorState: PropTypes.object,
	};

	addStar = () => {
		const { editorState, onChange } = this.props;
		var selectionState = editorState.getSelection();
		var anchorKey = selectionState.getAnchorKey();
		var currentContent = editorState.getCurrentContent();
		var currentContentBlock = currentContent.getBlockForKey(anchorKey);
		var start = selectionState.getStartOffset();
		var end = selectionState.getEndOffset();
		var selectedText = currentContentBlock.getText().slice(start, end);
		console.log(selectedText);
		const contentState = Modifier.replaceText(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			selectedText.toLowerCase(),
			editorState.getCurrentInlineStyle()
		);
		onChange(
			EditorState.push(editorState, contentState, "insert-characters")
		);
	};
	render() {
		return (
			<div
				className="rdw-option-wrapper"
				title="Lowercase"
				onClick={this.addStar}
			>
				<span style={{ color: "var(--d)", textTransform: "lowercase" }}>
					tt
				</span>
			</div>
		);
	}
}
class TextEditor extends Component {
	init() {
		return this.props.document
			? EditorState.createWithContent(
					convertFromRaw(JSON.parse(this.props.document))
			  )
			: EditorState.createEmpty();
	}
	state = {
		editor: this.init(),
		menu: true,
		preview: false,
	};
	paste = (event) => {
		if (!this.state.preview) {
			var items = (
				event.clipboardData || event.originalEvent.clipboardData
			).items;
			for (var index in items) {
				var item = items[index];
				if (item.kind === "file") {
					var blob = item.getAsFile();
					var reader = new FileReader();
					reader.onload = (event) => {
						let image = event.target.result;
						this.insertImage(image);
					};
					reader.readAsDataURL(blob);
				}
			}
		}
	};
	componentDidMount() {
		document.addEventListener("paste", this.paste);
	}
	componentWillUnmount() {
		document.removeEventListener("paste", this.paste);
	}
	insertImage = (image) => {
		const contentState = this.state.editor.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity(
			"IMAGE",
			"IMMUTABLE",
			{ src: image }
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set(this.state.editor, {
			currentContent: contentStateWithEntity,
		});
		this.editorStateChange(
			AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
		);
	};
	editorStateChange = (es) => {
		this.setState({ editor: es }, () => {
			if (es && typeof this.props.callback === "function")
				this.props.callback(
					JSON.stringify(convertToRaw(es.getCurrentContent()))
				);
		});
	};
	handleOptionChange = (event) => {
		console.log(event.target.value);
		window.docs.setState({ document_type: event.target.value });
	};
	render() {
		window.editor = this;
		if (this.state.preview)
			return (
				<div className="b1 rel nomarg">
					<Preview
						preview={true}
						document={this.props.document}
						name={this.props.name}
					/>
					<button
						className="close"
						onClick={(e) => {
							this.setState({ preview: false }, () => {
								if (window.docs?.state.toggle) {
									window.docs.edit();
								}
							});
						}}
					>
						X
					</button>
				</div>
			);
		return (
			<div className="b1 nomarg">
				{!this.state.menu && (
					<button
						id="togglemenu"
						onClick={(e) => this.setState({ menu: true })}
					>
						OPEN MENU
					</button>
				)}
				{this.state.menu && (
					<div id="dochead" className="wrap">
						<div id="doclet" style={{ padding: "10px" }}>
							<div className="b1 fifty">
								<input
									type={"text"}
									value={this.props.name}
									onChange={(e) =>
										this.props.setName(e.target.value)
									}
									placeholder={"Untitled Document"}
								></input>
								{this.props.created && (
									<div className="created-modified">
										<span>
											Created:{" "}
											{window.formatDate(
												this.props.created
											)}
										</span>
										<span>
											{this.props.modified &&
												` Modified: ${window.formatDate(
													this.props.modified
												)}`}
										</span>
									</div>
								)}
							</div>
							<div className="b2 wrap">
								<div
									className="b2"
									style={{ width: "auto" }}
								></div>
								{
									<button
										onClick={(e) =>
											this.setState({ preview: true })
										}
									>
										Preview{" "}
										{this.props.draft
											? "Draft"
											: `${window.capitalize(
													this.props.docType
											  )}`}
									</button>
								}
								<button
									disabled={window.docs.state.isDraftLoading}
									onClick={(e) => {
										if (!this.props.name)
											return window.flash(
												`Please choose a ${this.props.docType} name before saving.`
											);
										window.docs.setDraft();
									}}
								>
									{window.docs.state.isDraftLoading
										? "Please wait..."
										: this.props.draft
										? "Save Draft"
										: "Save as Draft"}
								</button>
								{this.props.draft && (
									<button
										onClick={() =>
											window.docs.setDraft(true)
										}
									>
										{window.docs.state.isRevertDraftLoading
											? "Please wait..."
											: "Revert Draft"}
									</button>
								)}
								<button
									disabled={window.docs.state.isLoading}
									onClick={(e) => window.docs.publish()}
								>
									{window.docs.state.isLoading
										? "Publishing"
										: "Publish"}
								</button>
								<button
									onClick={(e) => {
										this.setState({
											tags: !this.state.tags,
										});
									}}
								>
									{this.state.tags
										? "Close More"
										: "View More"}
								</button>
								{this.props.draft && this.props.published && (
									<button
										onClick={(e) => {
											window.docs.toggleDoc();
										}}
									>
										View Published
									</button>
								)}
								{(this.props.draft || this.props.published) && (
									<button
										onClick={(e) => {
											window.docs.delete();
										}}
									>
										Delete{" "}
										{window.capitalize(this.props.docType)}
									</button>
								)}
							</div>
							{this.state.tags && (
								<div
									style={{
										gridArea: "2 / 1 / span 1 / span 3",
									}}
									className="b1"
								>
									<div className="b1">
										<div className="c3">
											<div className="b1">
												<label>Category:</label>
												{window.isAdmin(
													window.app.state.type
												) ? (
													<SearchInput
														value={
															this.props.category
														}
														change={(e) => {
															if (window.docs)
																window.docs.changeCategory(
																	e
																);
														}}
														items={
															this.props
																.categories
														}
													/>
												) : (
													<select
														value={
															this.props.category
														}
														onChange={(e) => {
															if (window.docs)
																window.docs.changeCategory(
																	e.target
																		.value
																);
														}}
													>
														<option value="">
															Select Category
														</option>
														{this.props
															.categories &&
															this.props.categories.map(
																(u, i) => (
																	<option
																		value={
																			u
																		}
																		key={i}
																	>
																		{u.toUpperCase()}
																	</option>
																)
															)}
													</select>
												)}
											</div>
											<div className="b1">
												<label>Type:</label>
												<ArrayInput
													selected={
														this.props.docTypes ||
														[]
													}
													placeholder="Choose Type"
													change={(l, e) =>
														window.docs.setTypes(
															e.filter((a) => a)
														)
													}
													items={
														window.app.state
															.docTypes
													}
												/>
											</div>
											<div className="b1">
												<label>Tags:</label>
												<ArrayInput
													type="search"
													selected={
														this.props.tags || []
													}
													placeholder="Enter Tags"
													change={(l, e) =>
														window.docs.setState({
															tags: e.filter(
																(a) => a
															),
														})
													}
													items={this.props.taglist}
												/>
											</div>
										</div>
									</div>
									<button
										onClick={() =>
											this.setState({
												tags: !this.state.tags,
											})
										}
									>
										Close
									</button>
								</div>
							)}
							<div className="b1">
								<div className="b1">
									{this.props.draft && (
										<span className="warn">
											EDITING DRAFT
										</span>
									)}
									<div className="b2">
										<div className="">
											<label>Draft</label>
											<Toggle
												init={!this.props.draft}
												change={(e) => {
													console.log(e);
												}}
											/>
										</div>
										<div className="">
											<label>Published</label>
											<Toggle
												init={!this.props.published}
												change={(e) => {
													console.log(e);
												}}
											/>
										</div>
									</div>
									<button
										onClick={() =>
											this.setState({ menu: false })
										}
									>
										Close Menu
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="radio-box">
					<label>
						<input
							type="radio"
							value="pdf"
							key={"pdf"}
							checked={window.docs.state.document_type === "pdf"}
							onChange={this.handleOptionChange}
						/>
						PDF
					</label>
					<label>
						<input
							type="radio"
							value="video"
							key={"video"}
							checked={
								window.docs.state.document_type === "video"
							}
							onChange={this.handleOptionChange}
						/>
						PDF + URL
					</label>
				</div>
				{this.state.editor && (
					<Editor
						spellCheck={true}
						handlePastedText={(text, html, editorState) => {
							if (html) {
								const blocks = convertFromHTML(
									html
										.replace(/<b>/g, "")
										.replace(/<\/b>/, "")
								);
								this.editorStateChange(
									EditorState.set(this.state.editor, {
										currentContent:
											Modifier.replaceWithFragment(
												this.state.editor.getCurrentContent(),
												this.state.editor.getSelection(),
												ContentState.createFromBlockArray(
													blocks.contentBlocks,
													blocks.entityMap
												).getBlockMap()
											),
									})
								);
							}
							return false;
						}}
						toolbarCustomButtons={[
							<CustomOption1 />,
							<CustomOption2 />,
						]}
						toolbar={{
							image: {
								uploadEnabled: true,
								uploadCallback: window.docs.uploadImage,
							},
						}}
						toolbarClassName="flex sticky top-0 z-50 !justify-center mx-auto"
						editorClassName="mt-6 bg-white shadow-lg max-w-5xl mx-auto mb-12 border p-10"
						editorState={this.state.editor}
						onEditorStateChange={this.editorStateChange}
					></Editor>
				)}
			</div>
		);
	}
}
export { TextEditor, DocumentHTML, Preview };
